import React from 'react';
import { useParams } from 'react-router-dom';
import DesignOne from './DesignOne';

function ResponseResolver() {
  const { demo, status } = useParams();


  return (
    <>
      {demo === '1F7110F9-4F9F-4884-9D91-69422F95219C' && <DesignOne  status={status}/>}
    </>
  );
}

export default ResponseResolver;
