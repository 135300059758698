import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import DesignContainer from './DesignContainer';
import ResponseResolver from './ResponseResolver';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/1F7110F9-4F9F-4884-9D91-69422F95219C" element={<DesignContainer design="design1" />} />
        <Route path="/A60EC09D-8115-4F78-B6B5-602A87CEB713" element={<DesignContainer design="design2" />} />
        <Route path="/response_resolver/:demo/:status" element={<ResponseResolver/>} />
        <Route path="/design2" element={<DesignContainer design="design2" />} />
      </Routes>
    </Router>
  );
}

export default App;
