import React from 'react';

function DesignOne({status}) {
  
  function handleClick() {
    generateOrder(10000)
  }
  function generateOrder(amount) {
    const host = "paylands-demo-app.vercel.app";
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          service: "1D9D47C1-5689-421F-87FB-89749421C517",
          amount: amount,
          checkout_uuid: "D115AD7F-DB22-4AB3-9038-D2322429F795",
          signature: "SMGoFlRZP3Cy9YGKWQNURsoc",
          api_key: "a4745aae2c354fd0886befc6a9363610",
          url_ok: "https://"+host+"/response_resolver/1F7110F9-4F9F-4884-9D91-69422F95219C/ok",
          url_ko: "https://"+host+"/response_resolver/1F7110F9-4F9F-4884-9D91-69422F95219C/ko"
        })
    };

    fetch('https://sparkling-moon-1467.fly.dev/', options)
        .then(response => response.json())
        .then((response) => {
            console.log(response)
            window.location.href = "https://api.paylands.com/v1/sandbox/payment/process/"+response.order.token
        })
        .catch(err => console.error(err));
}
  if(status === 'ok'){
    return (
      <>
      <title>Publicar anuncios de pisos gratis &#8212; idealista</title>
      <script src="https://st3.idealista.com/static/common/js/lib/jquery/jquery-1.10.2.js" defer></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-consent-lang.js"></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-iab-integration.js"></script>
      <script src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-embed.js"></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/tealium/tealium-wrapper.js?20230329-0833"></script>
      <link rel="preload"
          href="https://st3.idealista.com/static/common/release/mavericks/landingcreatead.css?20230329-0833" as="style" />
      <link rel="stylesheet"
          href="https://st3.idealista.com/static/common/release/mavericks/landingcreatead.css?20230329-0833"
          type="text/css" />
  
      <header id="main-header" className="      help-center ide-header ">
          <div className="col-ide-lg-12 col-ide-xl-12 content ">
              <figure className="logo-container starter">
                  <a href='/' className='id-logo'> <img alt='idealista'
                          src='https://st3.idealista.com/static/common/img/idealista.svg' title='idealista'></img></a>
              </figure>
          </div>
      </header>
      <div className="steps" data-hide-on-modal>
          <ul>
              <li className=""><span>1.</span> Datos b&aacute;sicos</li>
              <li className=""><span>2.</span> Detalles</li>
              <li className=""><span>3.</span> Fotos</li>
              <li className="active" id="pago"><span>4.</span> Pago</li>
          </ul>
      </div>
      <div id="wrapper">
          <div id="main" data-islogged="false">
              <div className="container">
                  <div id="content">
                      <h1>Gracias por publicar tu anuncio.</h1>
                      
                  </div>
              </div>
          </div>
      </div>
      <footer className="main-footer">
          <div className="content content-basic-footer">
              <span className="icon-idealista-icon"></span>
              <h4>
                  <strong>idealista</strong>
                  <span className="ide-copyright"> Copyright &copy; 2000-2023 </span>
              </h4>
          </div>
      </footer>
    
  </>
    )
  }else if(status === 'ko'){
    return (
      <>
      <title>Publicar anuncios de pisos gratis &#8212; idealista</title>
      <script src="https://st3.idealista.com/static/common/js/lib/jquery/jquery-1.10.2.js" defer></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-consent-lang.js"></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-iab-integration.js"></script>
      <script src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-embed.js"></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/tealium/tealium-wrapper.js?20230329-0833"></script>
      <link rel="preload"
          href="https://st3.idealista.com/static/common/release/mavericks/landingcreatead.css?20230329-0833" as="style" />
      <link rel="stylesheet"
          href="https://st3.idealista.com/static/common/release/mavericks/landingcreatead.css?20230329-0833"
          type="text/css" />
  
      <header id="main-header" className="      help-center ide-header ">
          <div className="col-ide-lg-12 col-ide-xl-12 content ">
              <figure className="logo-container starter">
                  <a href='/' className='id-logo'> <img alt='idealista'
                          src='https://st3.idealista.com/static/common/img/idealista.svg' title='idealista'></img></a>
              </figure>
          </div>
      </header>
      <div className="steps" data-hide-on-modal>
          <ul>
              <li className=""><span>1.</span> Datos b&aacute;sicos</li>
              <li className=""><span>2.</span> Detalles</li>
              <li className=""><span>3.</span> Fotos</li>
              <li className="active" id="pago"><span>4.</span> Pago</li>
          </ul>
      </div>
      <div id="wrapper">
          <div id="main" data-islogged="false">
              <div className="container">
                  <div id="content">
                      <h1>No hemos podido completar el pago. ¿Quieres volver a probarlo?</h1>
                      
                      <div className="item-form">
                          <h2>Cantidad a pagar</h2>
                          <p>100€</p>
                      </div>
                      <div>
                            <button onClick={handleClick} className="btn regular">Vale, vamos
                              allá&nbsp;&raquo;</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <footer className="main-footer">
          <div className="content content-basic-footer">
              <span className="icon-idealista-icon"></span>
              <h4>
                  <strong>idealista</strong>
                  <span className="ide-copyright"> Copyright &copy; 2000-2023 </span>
              </h4>
          </div>
      </footer>
  </>
    )
  }else{
    return (
      <>
      <title>Publicar anuncios de pisos gratis &#8212; idealista</title>
      <script src="https://st3.idealista.com/static/common/js/lib/jquery/jquery-1.10.2.js" defer></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-consent-lang.js"></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-iab-integration.js"></script>
      <script src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/didomi/didomi-embed.js"></script>
      <script
          src="https://st3.idealista.com/static/common/release/mavericks/legacy/js/tealium/tealium-wrapper.js?20230329-0833"></script>
      <link rel="preload"
          href="https://st3.idealista.com/static/common/release/mavericks/landingcreatead.css?20230329-0833" as="style" />
      <link rel="stylesheet"
          href="https://st3.idealista.com/static/common/release/mavericks/landingcreatead.css?20230329-0833"
          type="text/css" />
  
      <header id="main-header" className="      help-center ide-header ">
          <div className="col-ide-lg-12 col-ide-xl-12 content ">
              <figure className="logo-container starter">
                  <a href='/' className='id-logo'> <img alt='idealista'
                          src='https://st3.idealista.com/static/common/img/idealista.svg' title='idealista'></img></a>
              </figure>
          </div>
      </header>
      <div className="steps" data-hide-on-modal>
          <ul>
              <li className=""><span>1.</span> Datos b&aacute;sicos</li>
              <li className=""><span>2.</span> Detalles</li>
              <li className=""><span>3.</span> Fotos</li>
              <li className="active" id="pago"><span>4.</span> Pago</li>
          </ul>
      </div>
      <div id="wrapper">
          <div id="main" data-islogged="false">
              <div className="container">
                  <div id="content">
                      <h1>Publicar tu anuncio de particular</h1>
                      
                      <div className="item-form">
                          <h2>Cantidad a pagar</h2>
                          <p>100€</p>
                      </div>
                      <div>
                            <button onClick={handleClick} className="btn regular">Vale, vamos
                              allá&nbsp;&raquo;</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <footer className="main-footer">
          <div className="content content-basic-footer">
              <span className="icon-idealista-icon"></span>
              <h4>
                  <strong>idealista</strong>
                  <span className="ide-copyright"> Copyright &copy; 2000-2023 </span>
              </h4>
          </div>
      </footer>
    
  </>
    )
  }
}

export default DesignOne;
