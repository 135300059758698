import React from 'react';
import DesignInespay from './DesignInespay';
import DesignOne from './DesignOne';

function DesignContainer({ design }) {
  return (
    <>
      {design === 'design1' && <DesignOne />}
      {design === 'design2' && <DesignInespay />}
    </>
  );
}

export default DesignContainer;
