import React from 'react';

function DesignInespay({status}) {
  
  function handleClick() {
    generateOrder(10000)
  }
  function generateOrder(amount) {
    const host = "paylands-demo-app.vercel.app";
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          service: "A60EC09D-8115-4F78-B6B5-602A87CEB713",
          amount: amount,
          signature: "341f7de8e6fc49da8d8736473af6b03a",
          api_key: "NWQ2NmE5NmY0NjBmNDEwOWIyNGMyMjQzZmIxYzQzZTE6",
          url_ok: "https://"+host+"/response_resolver/A60EC09D-8115-4F78-B6B5-602A87CEB713/ok",
          url_ko: "https://"+host+"/response_resolver/A60EC09D-8115-4F78-B6B5-602A87CEB713/ko"
        })
    };

    fetch('https://sparkling-moon-1467.fly.dev/demo/', options)
        .then(response => response.json())
        .then((response) => {
            console.log(response)
            window.location.href = "https://demo-ws-paylands.paynopain.com/v1/sandbox/payment/process/"+response.order.token
        })
        .catch(err => console.error(err));
}
  if(status === 'ok'){
    return (
      <>
      <div>TODO OK</div>
    
  </>
    )
  }else if(status === 'ko'){
    return (
      <>
     
                            <button onClick={handleClick} className="btn regular">Conprar</button>
     
  </>
    )
  }else{
    return (
      <>
     
                      <div>
                            <button onClick={handleClick} className="btn regular">Conprar</button>
                      </div>
     
  </>
    )
  }
}

export default DesignInespay;
