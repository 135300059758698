import React from 'react';
import './index.css';
function Dashboard() {
  return (
    <div className='main'>
      <h1>Welcome to the Paylands Demo app!</h1>
      <p>This is where you can view Paylands in action</p>
      <h2>How to use it</h2>
      <p>Please add you service_uuid in the url</p>
      <h2>Discover Paylands full potential</h2>
      <a href='https://docs.paylands.com' target="_blank" rel="noreferrer" >Click this link</a>
    </div>
  );
}

export default Dashboard;
